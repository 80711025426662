<template>
   <div class="flex-1 overflow-y-auto shadow-inner bg-gray-100 m-4">
    <p-loader v-if="isLoading" />
    <table v-else class="data-table">
      <thead>
        <tr>
          <th class="text-left" v-html="$tk('Reports.From')"></th>
          <th class="text-left" v-html="$tk('Reports.To')"></th>
          <th class="text-left" v-html="$tk('Reports.Product')"></th>
          <th class="text-left" v-html="$tk('Reports.Fee')"></th>
          <th class="text-left" v-html="$tk('Reports.OrderNumberSR')"></th>
          <th class="text-center" v-html="$tk('Reports.Confirmed')"></th>
          <th class="text-right" v-html="$tk('Reports.Quantity')"></th> 
          <th class="text-right" v-html="$tk('Reports.PricePrUnitExcludingVat')"></th>
          <th class="text-right" v-html="$tk('Reports.AmountExcludingVat')"></th>
          <th v-html="$tk('Reports.BestNumber')"></th>
          <th v-html="$tk('Reports.SerialNumber')"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(transaction, index) in transactions" :class="{'odd': index % 2 !== 0 }" :key="index">
         <td>{{transaction.locationNameFrom}}</td>
         <td>{{transaction.locationNameTo}}</td>
         <td>{{transaction.productName}}</td>
         <td>{{transaction.feeTypeName}}</td>
         <td>{{transaction.orderId}}</td>
         <td>{{transaction.confirmDate | date}}</td> 
         <td class="text-right">{{transaction.qtyCharged}}</td>
         <td class="text-right">{{numberFormat(transaction.amountPerUnit)}}</td>
         <td class="text-right">{{numberFormat(transaction.amountTotal)}}</td>
         <td>{{transaction.sendersRef}}</td>
         <td>{{transaction.receiversRef}}</td>
        </tr>
      </tbody>
      <tfoot>
        <td colspan="8" class="font-bold">{{$tk('Reports.Sum', true)}}</td>
        <td class="text-right font-bold">{{sumTotalAmount(transactions)}}</td>
        <td colspan="2"></td>
      </tfoot>
    </table>
  </div>
</template>

<script>

import http from "@/http"
import { sumBy } from "lodash"

export default {

  props: {    
    invoice: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      transactions: [],
      isLoading: false  
    }
  },

  methods: {
    numberFormat (number) {
      return new Intl.NumberFormat(this.$i18n.locale, { minimumFractionDigits: 2 }).format(number)
    },
    sumTotalAmount(transactions) {
      return this.numberFormat(sumBy(transactions, r => r.amountTotal))
    }
  },

  async created () {  

    this.isLoading = true    

    try {
      
      this.transactions = await http.get("invoicetransactions", { 
        params: {
          invoiceId: this.invoice.id
        } 
      })

    } catch (error) {
      this.$store.dispatch("notify", { type: "negative", text: error.reason })
    }

    this.isLoading = false

  }

}
</script>

<style scoped>

.data-table th {
  @apply p-3 leading-4 bg-gradient-to-b from-gray-500 to-gray-600 text-left;
  position: sticky;
  top: 0;
}

.data-table th.text-center {
  @apply text-center;
}

.data-table th.text-right {
  @apply text-right;
}

.data-table th.text-left {
  @apply text-left;
}

.data-table  tr {
  background-color: theme('colors.gray.50');
}

.data-table tr.odd {
  background-color: theme('colors.gray.100');
}

.data-table td {
  @apply p-2 border-gray-200 border;
}

.data-table tbody tr {
  cursor: pointer;
}
</style>