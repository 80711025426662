<template>
  <div class="flex-1 overflow-y-auto shadow-inner bg-gray-100 m-4">
    <p-loader v-if="isLoading" />
    <table v-else class="data-table">
      <thead>
        <tr>
          <th class="text-left" v-html="$tk('Reports.Id')"></th>
          <th class="text-left" v-html="$tk('Reports.Locations')"></th>
          <th class="text-left" v-html="$tk('Reports.Product')"></th>
          <th class="text-center" v-html="$tk('Common.General.Date')"></th>
          <th class="text-right" v-html="$tk('Reports.InboundVolume')"></th>
          <th class="text-right" v-html="$tk('Reports.Balance')"></th>
          <th class="text-right" v-html="$tk('Reports.OutboundVolume')"></th>
          <th class="text-right" v-html="$tk('Reports.Correction')"></th>
          <th class="text-right" v-html="$tk('Reports.RentDeferral')"></th>
          <th v-html="$tk('Reports.ExcludingCalculation')"></th>
          <th v-html="$tk('Reports.RentalBasis')"></th>
          <th
            class="text-right"
            v-html="$tk('Reports.PricePrUnitExcludingVat')"
          ></th>
          <th
            class="text-right"
            v-html="$tk('Reports.AmountExcludingVat')"
          ></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(stock, index) in stocks"
          :class="{ odd: index % 2 !== 0 }"
          :key="index"
        >
          <td class="text-left">{{ stock.locationId }}</td>
          <td class="text-left">{{ stock.locationName }}</td>
          <td class="text-left">{{ stock.productName }}</td>
          <td class="text-center">{{ stock.transactionDate | date }}</td>
          <td class="text-right">{{ numberFormat(stock.qtyIncoming, 0) }}</td>
          <td class="text-right">{{ numberFormat(stock.qtyBalance, 0) }}</td>
          <td class="text-right">{{ numberFormat(stock.qtyOutgoing, 0) }}</td>
          <td class="text-right">{{ numberFormat(stock.qtyCorrection, 0) }}</td>
          <td class="text-right">
            {{ numberFormat(stock.qtyCorrectionDelay, 0) }}
          </td>
          <td class="text-right">{{ numberFormat(stock.qtyExcluded, 0) }}</td>
          <td class="text-right">{{ numberFormat(stock.qtyCharged, 0) }}</td>
          <td class="text-right">{{ numberFormat(stock.amountPerUnit) }}</td>
          <td class="text-right">{{ numberFormat(stock.amountTotal) }}</td>
        </tr>
      </tbody>
      <tfoot>
        <td colspan="4" class="font-bold">{{ $tk("Reports.Sum", true) }}</td>
        <td class="text-right font-bold">
          {{ numberFormat(sumTotalAmount(stocks, "qtyIncoming"), 0) }}
        </td>
        <td class="text-right font-bold">
          {{ numberFormat(sumTotalAmount(stocks, "qtyBalance"), 0) }}
        </td>
        <td class="text-right font-bold">
          {{ numberFormat(sumTotalAmount(stocks, "qtyOutgoing"), 0) }}
        </td>
        <td class="text-right font-bold">
          {{ numberFormat(sumTotalAmount(stocks, "qtyCorrection"), 0) }}
        </td>
        <td class="text-right font-bold">
          {{ numberFormat(sumTotalAmount(stocks, "qtyCorrectionDelay"), 0) }}
        </td>
        <td class="text-right font-bold">
          {{ numberFormat(sumTotalAmount(stocks, "qtyExcluded"), 0) }}
        </td>
        <td class="text-right font-bold">
          {{ numberFormat(sumTotalAmount(stocks, "qtyCharged"), 0) }}
        </td>
        <td></td>
        <td class="text-right font-bold">
          {{ numberFormat(sumTotalAmount(stocks, "amountTotal")) }}
        </td>
      </tfoot>
    </table>
  </div>
</template>

<script>
import http from "@/http";
import { sumBy } from "lodash";

export default {
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      stocks: [],
      isLoading: false,
    };
  },

  methods: {
    numberFormat(number, decimals = 2) {
      return new Intl.NumberFormat(this.$i18n.locale, {
        minimumFractionDigits: decimals,
      }).format(number);
    },

    sumTotalAmount(transactions, field) {
      return sumBy(transactions, (r) => r[field]);
    },
  },

  async created() {
    this.isLoading = true;

    try {
      this.stocks = await http.get("invoicestocks", {
        params: {
          invoiceId: this.invoice.id,
        },
      });
    } catch (error) {
      this.$store.dispatch("notify", { type: "negative", text: error.reason });
    }

    this.isLoading = false;
  },
};
</script>

<style scoped>
.data-table th {
  @apply p-3 leading-4 bg-gradient-to-b from-gray-500 to-gray-600 text-left;
  position: sticky;
  top: 0;
}

.data-table th.text-center {
  @apply text-center;
}

.data-table th.text-right {
  @apply text-right;
}

.data-table th.text-left {
  @apply text-left;
}

.data-table tr {
  background-color: theme("colors.gray.50");
}

.data-table tr.odd {
  background-color: theme("colors.gray.100");
}

.data-table td {
  @apply p-2 border-gray-200 border;
}

.data-table tbody tr {
  cursor: pointer;
}
</style>
