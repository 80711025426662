<template>
  <div class="flex-1 flex flex-col overflow-hidden border bg-white border-gray-300 rounded">
    <div class="flex-1 overflow-y-scroll relative">
      <p-loader v-if="isLoading" />
      <table v-else class="data-table">
        <thead>
          <tr>
            <th class="text-left" v-html="$tk('Reports.Invoice')"></th>
            <th class="text-left" v-html="type === 'customer' ? $tk('Reports.Purchase') : $tk('Reports.Sale')"></th>
            <th class="text-left" v-html="$tk('Reports.Name')"></th>
            <th class="text-left" v-html="$tk('Common.General.Reference')"></th>
            <th class="text-center" v-html="$tk('Common.General.Date')"></th>
            <th class="text-center" v-html="$tk('Reports.Due')"></th>
            <th class="text-center" v-html="$tk('Reports.Paid')"></th>
            <th class="text-right" v-html="$tk('Reports.Vat')"></th>
            <th class="text-right" v-html="$tk('Reports.AmountIncludingVat')"></th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="(invoice, index) in filteredInvoices" 
            :key="index" 
            @click="onSelect(invoice)"
            :class="{'odd': index % 2 !== 0, 'overdue': invoice.isDue && !invoice.isSettled }"
          >
            <td class="font-bold w-24">            
              {{ invoice.id }}
            </td>
            <td class="font-bold w-24">
              {{ invoice.saleId || invoice.purchaseId }}
            </td>
            <td>
              {{ invoice.invoiceName }}
            </td>
            <td>
              {{ invoice.purchOrderFormNum + ' ' + invoice.customerRef }}
            </td>
            <td class="w-28 text-center">
              {{ invoice.invoiceDate | date }}
            </td>
            <td class="w-28 text-center">
              {{ invoice.dueDate | date }}
            </td>
            <td class="w-16 text-center">
              {{ invoice.isSettled ? 'Ja' : 'Nei' }}
            </td>
            <td class="w-32 text-right">
              {{ numberFormat(invoice.amountTax) }}
            </td>
            <td class="w-32 text-right">
              {{ numberFormat(invoice.amount) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <portal to="header">
      <div class="ml-1 text-xl font-medium text-gray-900">{{headingText}}</div>
    </portal>

    <portal to="filters">
      <div class="flex space-x-2">
        <PInput 
          class="w-48"
          :placeholder="$tk('Common.General.Search')" 
          :clearable="true"
          iconInsideLeft="search"
          v-model="term" 
          :wait="300"
        />
        <p-date-picker class="w-36" placeholder="Fra dato" v-model="invoiceDateLower" :clearable="false" :allOpen="true" />
        <p-date-picker class="w-36" placeholder="Til dato" v-model="invoiceDateUpper" :clearable="false" :allOpen="true" :rightAlign="true" />
      </div>
    </portal>

    <portal to="actions">
      <PButton 
        color="secondary" 
        icon="file-excel"
        :loading="isDownloading"
        @click="onDownload"
      >
        Excel
      </PButton>
    </portal>

    <invoice-dialog 
      v-if="selectedInvoice"
      :invoice="selectedInvoice"
      :type="type"
      @close="onClose" />
    

  </div>
</template>

<script>

import http from "@/http"
import { mapGetters } from "vuex"
import { format, sub, startOfMonth, lastDayOfMonth } from "date-fns"
import { filter } from 'lodash'
import InvoiceDialog from "../dialogs/InvoiceDialog"
import fileDownload from 'js-file-download'

export default {

  name: "Invoices",

  components: {
    InvoiceDialog
  },

  props: {
    type: {
      type: String,
      required: true
    },
    id: {
      type: String,
      default: ""
    }   
  },

  data () {
    return {      
      term: "",
      invoices: [],
      isLoading: false,   
      isDownloading: false,   
      selectedInvoice: null,
      invoiceDateUpper: format(lastDayOfMonth(new Date()), "yyyy-MM-dd"),
      invoiceDateLower: format(startOfMonth(sub(new Date(), { months: 2 })), "yyyy-MM-dd")
    }
  },

  computed: {
    
    ...mapGetters([
      "customer", 
      "location"
    ]),
    
    dateRange() {
      return `${this.invoiceDateUpper}-${this.invoiceDateLower}`
    },
    
    headingText() {
      return this.$tk('Reports.InvoicesFromSR', true)
    },

    params () {
      return {
        customerId: this.customer.id,
        invoiceDateLower: this.invoiceDateLower,
        invoiceDateUpper: this.invoiceDateUpper,
        //withCustomerTransactions: true
      }
    },

    filteredInvoices () {
        return filter(this.invoices, invoice => {
          const searchText = `${invoice.id} ${invoice.saleId} ${invoice.purchaseId} ${invoice.invoiceName} ${invoice.purchOrderFormNum} ${invoice.customerRef}`
          return searchText.toLowerCase().indexOf(this.term.toLowerCase()) >= 0
        })
      }

  },

  methods: {

    async getInvoices () {
      this.isLoading = true
      this.invoices = await http.get(`${this.type}invoices`, { params: this.params })
      this.isLoading = false      
    },

    numberFormat (number) {
      return new Intl.NumberFormat(this.$i18n.locale, { minimumFractionDigits: 2 }).format(number) // style: 'currency', currency: 'NOK'
    },

    onSelect (invoice) {
      this.$router.replace({ 
        name: "reports.Invoices", 
        params: { 
          type: this.type,
          id: invoice.id
        }
      })
    },

    async onDownload () {
      this.isDownloading = true
      const data = await http.get(`${this.type}invoices`, { params: { ...this.params, excel: "download"}, responseType: "blob" })
      fileDownload(data, `${format(new Date(), "yyyy-MM-dd")}_invoices.xlsx`)
      this.isDownloading = false
    },

    onClose () {
      this.$router.replace({ 
        name: "reports.Invoices", 
        params: { 
          type: this.type
        }
      })
    }
  },

  async created () {
    await this.getInvoices()
  },

  watch: {

    dateRange: function () {
      this.getInvoices()
    },

    // location: function () {
    //   this.getInvoices()
    // },

    id: {
      handler: async function (val) {
        if (val) {
          try {
            this.selectedInvoice = await http.get("CustomerInvoice", { 
              params: { 
                id: this.id,
                withSale: true
                }
              }
            )
          } catch {
            this.$notifyError("Fant ikke faktura", this.id)
          }
        } else {
          this.selectedInvoice = null
        }
      },
      immediate: true
    }
    
  }
}
</script>

<style scoped>
  thead > tr >th {
    @apply sticky;
  }

  thead > tr > th.text-center {
    @apply text-center;
  }

  thead > tr > th.text-right {
    @apply text-right;
  }

  tbody > tr.overdue {
    @apply bg-red-400 !important
  }
</style>